import React from 'react';
import Header from './Header';

function App() {
  return (
    <div>
      <Header />
      {/* <img src={logo} className="App-logo" alt="logo" /> */}
      <main>This is the main content !!!TEST!!!!!</main>
    </div>
  );
}

export default App;

